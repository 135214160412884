<template>
    <client-page>
        <mypage-layout title="대량구매요청">
            <page-section class="py-0">
                <form-input code="bulk-purchase" skin="bulk-purchase" />
            </page-section>
        </mypage-layout>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import MypageLayout from "@/components/client/templates/mypage-layout.vue";
import PageSection from "@/components/client/templates/page-section.vue";
import FormInput from "@/components/client/form/form-input.vue";

export default {
    components: {
        ClientPage,
        MypageLayout,
        PageSection,
        FormInput,
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init: function () {
            if (!this.accessToken) {
                this.$router.push("/login");
            }
        },
    },
};
</script>
