<template>
    <client-page>
        <template #subHead>
            <sub-visual-primary tit="고객센터" bg="/images/center/visual.jpg">
                <template #path>
                    <visual-breadcrumbs />
                </template>
            </sub-visual-primary>
        </template>

        <page-section class="page-section--first page-section--last" containerSize="container--lg">
            <!-- <v-row class="row--contents flex-xl-nowrap"> -->
            <v-row class="flex-xl-nowrap">
                <v-col v-if="$vuetify.breakpoint.xlOnly" cols="12" xl="auto">
                    <div class="w-xl-200px">
                        <!-- PC -->
                        <snb v-model="activeItem" title="고객센터" :items="snbItems" />
                    </div>
                </v-col>
                <v-col>
                    <slot />
                </v-col>
            </v-row>
        </page-section>
    </client-page>
</template>

<script>
import ClientPage from "@/components/client/templates/client-page.vue";
import PageSection from "@/components/client/templates/page-section.vue";

import SubVisualPrimary from "@/components/client/sub/sub-visual-primary.vue";
import VisualBreadcrumbs from '@/components/client/inc/visual-breadcrumbs.vue';
import Snb from "@/components/client/list/snb.vue";

const snbItems = [
    {
        title: "공지사항",
        to: { path: "/center/notice" },
    },
    {
        title: "자주 묻는 질문",
        to: { path: "/center/faq?type=MEMBERSHIP" },
    },
    {
        title: "1:1 문의",
        to: { path: "/center/questions" },
    },
];

export default {
    components: {
        ClientPage,
        PageSection,
        SubVisualPrimary,
        VisualBreadcrumbs,
        Snb,
    },
    props: {
        status: { type: String, default: null },
        mode: { type: String, default: "ongoing" },
    },
    data: () => ({
        snbItems,
        activeItem: undefined,
    }),
    computed: {
        breadcrumbsItems() {
            const items = [
                // { text: "HOME", to: { path: "/" } },
                // { text: "CScenter", to: { path: "/center/notice" } },
                { text: "HOME" },
                { text: "CScenter" },
            ];
            if (this.activeItem) {
                items.push({
                    text: this.activeItem?.title,
                    // to: this.activeItem?.to,
                });
            }
            return items.map((item) => ({ ...item, disabled: false }));
        },
    },
};
</script>